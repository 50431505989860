<script lang="ts" setup>
import { useStore } from "@/entities";
import { sockets } from "@/entities";
import { computed, onMounted, ref } from "vue";
import {
  loader,
  MobileHeader,
  AssideNav,
  MainHeader,
  CreateTask,
  ResetPersonal,
  SetConfirmDismiss,
  AddSubOrgs,
  Events,
  AddPerson,
  ResetPassword,
  CreateDepartment,
  AddPosition,
  UpdateWidget,
  HamburgerMenu,
  DeleteMessage,
  Connection,
  Request
} from "@/widgets";
import { useRoute } from "vue-router";

const _container = ref<HTMLDivElement>();
const store = useStore();
const loaderState = computed(() => store.state.componentLoader.status);
const route = useRoute()

if (document.cookie.length > 0) {
  const cookie = decodeURIComponent(document.cookie).split("=");
  if (cookie[0] === "sd-user") {
    const user = JSON.parse(cookie[1]);
    store.commit("personal/SET_ID", user.id);
    store.commit("user/SET_USER", user);
  }
}
store.commit("componentLoader/TOGGLE_STATE");

const modal = computed(() => store.state.titles.modal)

sockets.on("disconnect", () => {
  store.commit("user/SET_DISCONNECTED")
})

sockets.on("connect", () => {
  store.commit("user/SET_CONNECTED")
})

sockets.io.on("reconnect_attempt", () => {
  store.commit("user/INCREMENT_ATTEMPT")
})

sockets.io.on("reconnect_failed", () => {
  store.commit("user/FAIL_RECONNECT")
})

sockets.on("new_event", (payload: { link: string, message: string, personalId: number, typeId: number }) => {
  store.commit("events/PUSH_EVENT", payload)
})

sockets.on("new_event_emit", (payload: { link: string, message: string, personalId: number, typeId: number }) => {
  store.commit("events/SET_NOTIFICATIONS", payload)
})

sockets.on("task_event", (payload: { type: string, notification: iStore.iNotifications, task: iStore.Task }) => {
  store.commit("events/SET_NOTIFICATIONS", payload.notification)
  store.commit("tasks/TASK_EVENT", { task: payload.task, type: payload.type })
})

sockets.on("notification", (payload: { link: string | null, action: "TaskInfo" | "Request" | null, message: string, personalId: 1, typeId: 4 }) => {
  store.commit("events/SET_NOTIFICATIONS", payload)
})

sockets.on("setMessages", (payload) => {
  store.commit("chat/SET_MESSAGES", payload);
});

sockets.on("emit_message", (payload) => {
  store.commit("chat/SET_MESSAGE", payload);
});

sockets.on("delete_message", (payload: { messageId: number, roomName: string }) => {
  store.commit("chat/DELETE_MESSAGE", payload)
  store.commit("titles/CLOSE_MODAL", payload)
})

sockets.on("edit_message", (msg) => {
  store.commit("chat/SET_EDITED_MESSAGE", msg)
})

sockets.on("typing", (payload: { orgId: number, personalId: number }) => {
  store.commit("chat/TYPINGS", payload)
  store.dispatch("chat/CHECK_TYPINGS")
})

sockets.on("setReadedMessage", (payload) => {
  store.commit("chat/SET_MESSAGE_TO_READED", payload);
});

sockets.on("setOnline", (payload) => {
  store.commit("chat/SET_ONLINE", payload);
});

sockets.on("setOffline", (payload) => {
  store.commit("chat/SET_OFFLINE", payload);
});

sockets.on("stucture", (payload: { eventName: "record_event" | "contacts_event" | "department_event" | "personal_event" | "position_event" | "organization_event" | "roles_event", type: "updated" | "created" | "deleted", data: any }) => {
  if (payload.eventName === "organization_event") {
    store.commit("personal/SET_ORGANIZATION", { type: payload.type, data: payload.data })
  } else if (payload.eventName === "department_event") {
    store.commit("personal/SET_DEPARTMENT", { type: payload.type, data: payload.data })
  } else if (payload.eventName === "personal_event") {
    store.commit("personal/SET_PERSON", { type: payload.type, data: payload.data })
  } else if (payload.eventName === "contacts_event") {
    store.commit("personal/SET_PERSON_CONTACTS", { type: payload.type, data: payload.data })
  } else if (payload.eventName === "position_event") {
    store.commit("personal/SET_POSITION", { type: payload.type, data: payload.data })
  } else if (payload.eventName === "record_event") {
    store.commit("personal/SET_PERSON_RECORD", { type: payload.type, data: payload.data })
  } else if (payload.eventName === "roles_event") {
    store.commit("personal/SET_PERSON_ROLES", { type: payload.type, data: payload.data })
  }
})

sockets.on("task_message", (payload: { taskId: number, files: [], message: string, senderId: number, createdAt: string, typeId: number }) => {
  if (route.fullPath === `/requests/info/${payload.taskId}?state=chat`) store.commit("tasks/PUSH_TASK_MESSAGE", payload)
})

store.commit("chat/SET_SELF_ID", { id: store.state.personal.id, orgId: store.state.user.info.organization.orgId })

function toggleDropdown() {
  store.commit("titles/CHANGE_OPENED_STATE")
  store.commit("titles/TOGGLE_CREATE")
}

function checkClosedCreateModal(e: Event) {
  const list = [...(e.target as HTMLDivElement).classList.values()]
  const menu = list.every(c => /dropdown-menu/.test(c))
  if (!menu && store.state.titles.opened) toggleDropdown()
}

Promise.all([store.dispatch("events/GET_NOTIFICATIONS", 1)])
  .then(() => {
    sockets.connect();
    store.commit("componentLoader/TOGGLE_STATE");
  }).catch(error => {
    console.log(error)
  })

onMounted(() => {
  if ("reset" in store.state.user.info) {
    store.commit("titles/SET_MODAL", "password")
  }
  if (screen.width <= 480) {
    store.commit("app/CHANGE_STATE_MOBILE", true);
  } else {
    store.commit("app/CHANGE_STATE_MOBILE", false);
  }
});
</script>
<template>
  <loader v-if="loaderState"></loader>
  <Connection v-else-if="!$store.state.user.connected"/>
  <div class="container" v-else ref="_container" @click="checkClosedCreateModal">
    <AssideNav class="asside" v-if="!store.state.app.mobile" />
    <div class="container__view">
      <MainHeader v-if="!store.state.app.mobile" class="main-header" />
      <MobileHeader class="mobile-header" v-if="store.state.app.mobile" />
      <main class="main">
        <router-view></router-view>
      </main>
    </div>
    <Events />
    <CreateTask v-if="modal === 'createTask'" />
    <AddPerson v-if="modal === 'addPerson'" />
    <ResetPassword v-if="modal === 'password'" />
    <CreateDepartment v-if="modal === 'createDeparment'" />
    <AddSubOrgs v-if="modal === 'addSubOrgs'" />
    <AddPosition v-if="modal === 'addPosition'" />
    <SetConfirmDismiss v-if="modal === 'setConfirmDismiss'" />
    <ResetPersonal v-if="modal === 'resetPersonal'" />
    <UpdateWidget v-if="modal === 'updateWidget'" />
    <DeleteMessage v-if="modal === 'delete-message'"/>
    <Request v-if="modal === 'request'"/>
    <HamburgerMenu />
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-grow: 1;
}

.container__view {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: calc(100% - 2em);
}
</style>
