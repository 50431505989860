<script setup lang="ts">
import { PropType, defineProps, defineEmits, ref } from "vue";

const emit = defineEmits(["inputVal"]); // Define the emitted event
const modelVal = ref(""); // Reactive reference for the input value

// Define props
const props = defineProps({
  readonly: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  textVal: {
    type: String as PropType<string>,
    required: false,
    default: "",
  },
});

function updateValue(e: Event) {
  const newValue = (e.target as HTMLInputElement).value;
  modelVal.value = newValue;
  emit("inputVal", newValue
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("-"));
}
</script>

<template>
  <input :class="{ readonly: props.readonly }" type="text" :value="props.textVal" @input="updateValue" :readonly="props.readonly" />
</template>

<!-- Scoped styles -->
<style scoped lang="scss">
input {
  justify-self: auto;
  padding: 0.5em;
  border: 0.1em solid var(--fourth-border-color);
  outline: none;
  border-radius: 0.4em;
  word-wrap: break-word !important;
  background-color: var(--secondary-background-color);
}

.readonly {
  cursor: default !important;
  border: 0.1em solid transparent !important;
  background-color: transparent !important;
}
</style>
