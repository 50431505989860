export function capitalLetter(e: Event) {
  const input = e.target as HTMLInputElement;
  return (input.value = input.value
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("-"));
}

export function capitalFirstLetter(e: Event) {
  const input = e.target as HTMLInputElement;
  return (input.value = input.value.charAt(0).toUpperCase() + input.value.slice(1))
}