import { Module } from "vuex";

export const titleState: Module<{ title: string, modal: false | string, createClose: boolean, opened: boolean }, iStore.RootState> = {
  namespaced: true,
  state: {
    title: "",
    modal: false,
    opened: false,
    createClose: false
  },
  mutations: {
    TOGGLE_CREATE(state) {
      state.createClose = !state.createClose
    },
    SET_TITLE(state, payload) {
      state.title = payload;
    },
    SET_MODAL(state, payload) {
      state.modal = payload
    },
    CLOSE_MODAL(state) {
      state.modal = false
    },
    CHANGE_OPENED_STATE(state) {
      state.opened = !state.opened
    }
  },
};
