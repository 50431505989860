import { doRequest } from "@/helpers";
import { map } from "@/helpers/tasksMap";
import { PropType } from "vue";
import { Module } from "vuex";



export const tasksState: Module<iStore.Tasks, iStore.RootState> = {
  namespaced: true,
  mutations: {
    TASK_EVENT(state, payload) {
      if (payload.type === "updated") {
        if (state.task && state.task.id === payload.task.id) state.task = payload.task
        if (state.allTasks && Array.isArray(state.allTasks)) {
          const all = state.allTasks.findIndex(t => t.id === payload.task.id)
          if (all !== -1) state.allTasks[all] = payload.task
        }
      } else {
        if (!state.allTasks) state.allTasks = []
        state.allTasks.push(payload.task)
      }
    },
    PUSH_TASK_MESSAGE(state, payload) {
      if (state.task) state.task.info.log.push(payload)
    },
    SET_TASKS_OF_SELECTED_DAY(state, payload) {
      state.taskOfSelectedDay = {
        day: payload.day,
        tasks: payload.tasks || [],
      };
    },
    SET_COUNTS(state, payload) {
      state.counts = payload;
      if (Array.isArray(payload)) {
        const all = (payload as iStore.Counts[]).find((count) => count.priority === 1);
        if (!all) return;
        state.selected = all.count;
      }
    },
    SET_TASKS(state, payload) {
      state.allTasks = payload;
    },
    SET_SELECTED_TASKS_COUNT(state, payload) {
      state.selected = payload;
    },
    SET_TASK(state, payload) {
      payload.info.log = payload.info.log.reverse()
      state.task = payload;
    },
  },
  actions: {
    GET_TASKS_OF_MONTH({ commit }, payload) {
      return new Promise(function (resolve) {
        doRequest(`/tasks/month?${new URLSearchParams(payload)}`, { method: "GET" })
          .then(({ response }) => resolve(response))
          .catch((error) => {
            commit("events/PUSH_EVENT", {
              id: undefined,
              message: error,
              type: "error",
            }, { root: true });
          });
      });
    },
    GET_STATS({ commit }) {
      return new Promise(function (resolve) {
        doRequest(`/tasks/stats`, { method: "GET" })
          .then(({ response }) => {
            const taskCounter: {
              title: string;
              count: number;
              icon: string;
              link: string;
              priority: number;
            }[] = [];
            const temp = Object.entries(response);
            for (let i = 0; i < temp.length; i++) {
              const val = map[temp[i][0] as keyof typeof map];
              val.count = temp[i][1] as number;
              taskCounter.push(val);
            }
            taskCounter.sort((a, b) => a.priority - b.priority);
            commit("SET_COUNTS", taskCounter);
            return resolve(true);
          })
          .catch((error) => {
            commit("events/PUSH_EVENT", {
              id: undefined,
              message: error,
              type: "error",
            }, { root: true });
          });
      });
    },
    // GET_REQUIRED_DATA({ commit }) {
    //   return new Promise((resolve) => {
    //     doRequest("/tasks/data", { method: "GET" })
    //       .then(({ response }) => {
    //         commit("personal/SET_PERSONAL_LIST", response.personal, { root: true })
    //         commit("chat/SET_ORGS_LIST", response.organization, { root: true })
    //         commit("chat/SET_PERSONAL_LIST", response.personal, { root: true })
    //         commit("personal/SET_DATA", response, { root: true })
    //         commit("SET_REQUIRED_DATA", response);
    //         const taskCounter: {
    //           title: string;
    //           count: number;
    //           icon: string;
    //           link: string;
    //           priority: number;
    //         }[] = [];
    //         const temp = Object.entries(response.stats);
    //         for (let i = 0; i < temp.length; i++) {
    //           const val = map[temp[i][0] as keyof typeof map];
    //           val.count = temp[i][1] as number;
    //           taskCounter.push(val);
    //         }
    //         taskCounter.sort((a, b) => a.priority - b.priority);
    //         commit("SET_COUNTS", taskCounter)
    //         return resolve(true);
    //       })
    //       .catch((error) => {
    //         commit("events/PUSH_EVENT", {
    //           id: undefined,
    //           message: error,
    //           type: "error",
    //         }, { root: true });
    //       });
    //   }).catch((error) => {
    //     commit("events/PUSH_EVENT", {
    //       id: undefined,
    //       message: error,
    //       type: "error",
    //     }, { root: true });
    //   });
    // },
    GET_ALL_TASKS({ commit }, payload: Record<string, string>) {
      return new Promise((resolve) => {
        const query: Record<string, string> = { offset: payload.offset, count: payload.count };
        if (payload.filter) query.filter = payload.filter;
        if (payload.sort) query.sort = payload.sort;
        if (payload.desc) query.desc = payload.desc;
        getTasks(query)
          .then(({ response }) => {
            commit("SET_TASKS", response);
            return resolve(true);
          })
          .catch((error) => {
            commit("events/PUSH_EVENT", {
              id: undefined,
              message: error,
              type: "error",
            }, { root: true });
          });
      }).catch((error) => {
        commit("events/PUSH_EVENT", {
          id: undefined,
          message: error,
          type: "error",
        }, { root: true });
      });
    },
  },
  getters: {
    GET_COUNTS(state) {
      return state.counts;
    },
    GET_TASK_OF_DAY(state) {
      return state.taskOfSelectedDay;
    },
    GET_ALL_TASKS(state) {
      return state.allTasks;
    },
  },
};

function getTasks(payload: Record<string, string>): Promise<{ response: iStore.Task[] }> {
  return new Promise(function (resolve) {
    doRequest(`/tasks/get?${new URLSearchParams(payload)}`, { method: "GET" }).then(({ response }) => {
      resolve({ response });
    });
  });
}
